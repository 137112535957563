@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #333333 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiList-root {
  background-color: #fcedbf !important;
}
.MuiDialogTitle-root {
  background-color: #cabd97 !important;
  color: "white" !important;
}
.MuiButton-containedPrimary {
  background-color: #cabd97 !important;
}

.MuiPaper-root {
  background-color: transparent !important;
}

.MuiBox-root {
  overflow: hidden !important;
}

.fact {
  background-color: rgba(255, 255, 255, 0.807) !important;
}

.custom-button {
  background-color: #ed8200 !important;
  font-size: 20px !important;
  border-radius: 10px !important;

  font-weight: bold !important;
  box-shadow: none !important;

  &:hover {
    background-color: #b56300 !important;
    color: white !important;
  }
}

.MuiPaper-rounded {
  border-radius: 20px !important;
}

.css-v8qbgq {
  /*background-color: #fff9e7 !important;*/
  background-color: #ffebd4;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2% !important;
  border: 3px solid rgba(45, 98, 89, 0.297);
  border-radius: 10px;
  max-width: 700px !important;
}
/*root #fff9e7*/
.css-8apw0y {
  background-color: #333333 !important;
}

/* card */
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root {
  margin: 15% !important;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
}
.logo {
  border: 10px groove #fe7733;
  border-radius: 50%;
  margin-top: 20px;
}
